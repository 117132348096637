w<script>
import UserSelect from '../components/UserSelect.vue';

export default {
  name: 'Admin',
  components: { UserSelect },
  props: [],
  data() {
    return {
      roles: [
        { role: 'viewer', title: 'Viewer' },
        { role: 'owner', title: 'Owner' },
        { role: 'approver', title: 'Approver' },
        { role: 'editor', title: 'Editor' },
      ],
      valid: true,
      owners: {},
      editors: {},
      viewers: {},
      newUser: {},
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },
  computed: {
    newUserRole: {
      get() {
        return '';
      },
      set(v) {
        if (v) this.newUser.role = v;
      },
    },
    newUserEmail: {
      get() {
        return '';
      },
      set(v) {
        if (v) this.newUser.email = v;
      },
    },
    newUserProject: {
      get() {
        return (
          this.newUser.projectKey || (this.projects[0] && this.projects[0].key)
        );
      },
      set(v) {
        if (v) this.newUser.projectKey = v;
      },
    },
    projectId() {
      return this.$store.state.projectId;
    },
    projects: {
      get() {
        const projects = this.$store.getters.adminProjects;
        return projects.filter(p => p.key === this.projectId);
      },
    },
    allUsers: {
      get() {
        return this.$store.state.allUsers;
      },
    },
  },
  mounted() {},
  created() {},
  methods: {
    getOwners() {},
    getRoleUsers(project, role) {
      const users = Object.keys(project.roles).reduce((users, user) => {
        if (project.roles[user] === role) users.push(user);
        return users;
      }, []);
      return users;
    },
    getEditors() {},
    setOwners() {},
    setUsersToProjectRole() {},
    async addUser() {
      const { $auth: auth } = this;
      const { role, email } = this.newUser;
      let { projectKey } = this.newUser;

      if (!projectKey) projectKey = this.newUserProject;

      const project = this.projects.filter(p => p.key === projectKey)[0];

      try {
        await this.$store.dispatch('addUser', {
          auth,
          project,
          role,
          email,
        });
        this.clearForm();
      } catch (err) {
        console.error(err);
      }
    },
    clearForm() {
      this.newUserRole = '';
      this.newUserEmail = '';
    },
  },
};
</script>

<template>
  <div>
    <v-container fluid grid-list-md>
      <v-data-iterator :items="projects" item-key="key" hide-default-footer>
        <template #item="{ item }">
          <v-card class="elevation-4 mb-4">
            <v-card-title>{{ item.key }} - {{ item.name }}</v-card-title>
            <v-card-text>
              <UserSelect :project="item" @change="setUsersToProjectRole" />
            </v-card-text>
          </v-card>
        </template>
        <template #footer>
          <div />
        </template>
      </v-data-iterator>
      <v-card class="elevation-4 mb-4">
        <v-card-title>New User</v-card-title>
        <v-card-text>
          <v-form ref="newUser" v-model="valid" @submit.prevent="addUser">
            <v-row dense>
              <v-col cols="3">
                <v-select
                  v-model="newUserProject"
                  :items="projects"
                  :rules="[v => !!v || 'Item is required']"
                  item-text="name"
                  item-value="key"
                  label="Project"
                  required
                  dense
                />
              </v-col>
              <v-col cols="3" class>
                <v-select
                  v-model="newUserRole"
                  :items="roles"
                  :rules="[v => !!v || 'Item is required']"
                  item-text="title"
                  item-value="role"
                  label="Role"
                  required
                  dense
                />
              </v-col>
              <v-col cols="6" class="pl-3">
                <v-text-field
                  v-model="newUserEmail"
                  :rules="emailRules"
                  dense
                  label="E-mail"
                  required
                />
              </v-col>
            </v-row>
            <v-row justify="start" dense>
              <v-col cols="12" md="2">
                <v-btn :disabled="!valid" type="submit">submit</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
