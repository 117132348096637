<script>
export default {
  name: 'UserSelect',
  props: {
    role: { type: String, default: '' },
    project: { type: Object, default: () => {} },
  },
  data() {
    return {
      searchInput: {},
    };
  },
  computed: {
    users() {
      return {
        owners: this.owners,
        approvers: this.approvers,
        editors: this.editors,
        viewers: this.viewers,
      };
    },
    requiresApproval() {
      return this.project && this.project.approval;
    },
    roles() {
      return {
        owners: true,
        approvers: this.requiresApproval,
        editors: true,
        viewers: true,
      };
    },
    activeRoles() {
      return Object.keys(this.roles).filter(role => Boolean(this.roles[role]));
    },
    viewers: {
      get() {
        return (
          this.$store.getters.getUsersInRole(this.project, 'viewer') || []
        ).sort(this.compareValues('displayName'));
      },
      set(users) {
        this.$store.dispatch('setUsersInRole', {
          project: this.project,
          users,
          role: 'viewer',
        });
      },
    },
    editors: {
      get() {
        const editors = this.$store.getters.getUsersInRole(
          this.project,
          'editor'
        );
        const approvers =
          (!this.requiresApproval &&
            this.$store.getters.getUsersInRole(this.project, 'approver')) ||
          [];

        return ([...approvers, ...editors] || []).sort(
          this.compareValues('displayName')
        );
      },
      set(users) {
        this.$store.dispatch('setUsersInRole', {
          project: this.project,
          users,
          role: 'editor',
        });
      },
    },
    approvers: {
      get() {
        return (
          (
            this.$store.getters.getUsersInRole(this.project, 'approver') || []
          ).sort(this.compareValues('displayName')) || []
        );
      },
      set(users) {
        this.$store.dispatch('setUsersInRole', {
          project: this.project,
          users,
          role: 'approver',
        });
      },
    },
    owners: {
      get() {
        return (
          this.$store.getters.getUsersInRole(this.project, 'owner') || []
        ).sort(this.compareValues('displayName'));
      },
      set(users) {
        this.$store.dispatch('setUsersInRole', {
          project: this.project,
          users,
          role: 'owner',
        });
      },
    },
    availableUsers: {
      get() {
        return this.allUsers;
      },
    },
    allUsers: {
      get() {
        const allStateUsers = [...this.$store.state.allUsers]
          .sort(this.compareValues('displayName'))
          .map(user => {
            const { uid, email } = user;
            let { displayName } = user;
            if (!displayName) displayName = email;
            return { uid, displayName, email };
          });

        return allStateUsers;
      },
    },
  },
  methods: {
    updateUsers(role, users) {
      this[role] = users;
      this.searchInput[role] = '';
    },
    userItem(item) {
      return item.displayName
        ? `${item.displayName} - [${item.email}]`
        : item.email;
    },
    userSelectedItem(data) {
      const last = data.parent.value.length - 1 === data.index;
      return `${
        data.item.displayName ? data.item.displayName : data.item.email
      }${!last ? ', ' : ''}`;
    },
    compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        const aKey = Object.prototype.hasOwnProperty.call(a, key);
        const bKey = Object.prototype.hasOwnProperty.call(b, key);

        if (!aKey || !bKey) {
          return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
      };
    },
  },
};
</script>

<template>
  <div>
    <v-autocomplete
      v-for="activeRole in activeRoles"
      :key="activeRole"
      :value="users[activeRole]"
      :label="activeRole.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())"
      :items="allUsers"
      :search-input.sync="searchInput[activeRole]"
      item-text="displayName"
      item-value="uid"
      multiple
      @change="users => updateUsers(activeRrole, users)"
    >
      <template #selection="{ item }">
        <span class="selected-user">
          <span class="text-body-2">{{ item.displayName }}</span>
          <span
            v-if="item.displayName != item.email"
            class="font-weight-light font-italic text-caption"
            >{{ item.email }}</span
          >
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<style>
.selected-user:not(:last-of-type)::after {
  content: ', ';
  padding-right: 1ex;
}
.selected-user span + span::before {
  content: '(';
  padding-left: 0.1em;
}
.selected-user span + span::after {
  content: ')';
}
</style>
